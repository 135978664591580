import QR from "qrcode"
import swal from "sweetalert2/dist/sweetalert2.js"
import { standardConfirmOptions } from "@planningcenter/sweetalert2"
import "@planningcenter/sweetalert2/css/sweetalert2.css"

const showQrButton = document.getElementById("show-qr")
const qrWrapper = document.getElementById("qrcode-wrapper")
const qrImage = document.getElementById("qrcode")
const qrDownload = document.getElementById("qrcode-download")

const showIosDownloadLinkModal = document.getElementById(
  "show-ios-download-modal",
)
const showAndroidDownloadLinkModal = document.getElementById(
  "show-android-download-modal",
)

function warningConfirm(props) {
  return swal.mixin(standardConfirmOptions).fire(props)
}

function addUrlToClipboard() {
  const mobileOrTabletUserAgent = /Android|iPhone|iPad/

  if (mobileOrTabletUserAgent.test(navigator.userAgent)) {
    navigator.clipboard
      .writeText(window.location.href)
      .then(() => console.log("Page URL copied to clipboard"))
      .catch((err) => console.log("Failed to copy: ", err))
  }

  const links = document.querySelector(".setup-app__links")

  function checkScroll() {
    const { bottom } = links.getBoundingClientRect()
    links.classList.toggle("is-fixed", bottom === window.innerHeight)
  }

  checkScroll()
  window.addEventListener("scroll", checkScroll)
}

showIosDownloadLinkModal.addEventListener("click", function () {
  window.hasOrganization && addUrlToClipboard()

  return warningConfirm({
    title: "Continue to App Store?",
    text: "Click 'Continue' to download Church Center on App Store.",
    confirmButtonText: "Continue",
  }).then(({ isConfirmed }) => {
    if (isConfirmed) {
      window.location =
        "https://itunes.apple.com/us/app/my-church-center/id1357742931?mt=8&ls=1&ign-mpt=uo%3D4"
    }
  })
})

showAndroidDownloadLinkModal.addEventListener("click", function () {
  window.hasOrganization && addUrlToClipboard()

  return warningConfirm({
    title: "Continue to Google Play?",
    text: "Click 'Continue' to download Church Center on Google Play.",
    confirmButtonText: "Continue",
  }).then(({ isConfirmed }) => {
    if (isConfirmed) {
      window.location =
        "https://play.google.com/store/apps/details?id=com.ministrycentered.churchcenter"
    }
  })
})

const qrOptions = {
  errorCorrectionLevel: "H",
  margin: 0,
  width: 150,
  color: {
    dark: "#313437",
    light: "#FFF",
  },
}

if (window.hasOrganization) {
  QR.toDataURL(window.location.toString(), qrOptions, (error, url) => {
    if (error) {
      return
    }
    qrImage.src = url
    qrDownload.href = url
  })

  showQrButton.addEventListener("click", function () {
    qrWrapper.classList.add("d-f")
    qrWrapper.classList.remove("d-n")
    showQrButton.classList.add("d-n")
  })
}
